import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetSettlementOrdersResponseData = {
  id: bnplMerchants.SettlementOrderMany["id"];
  created: bnplMerchants.SettlementOrderMany["created"];
  updated: bnplMerchants.SettlementOrderMany["updated"];
  archived: bnplMerchants.SettlementOrderMany["archived"];
  settlementId: bnplMerchants.SettlementOrderMany["settlement_id"];
  orderId: bnplMerchants.SettlementOrderMany["order_id"];
  orderAmount: bnplMerchants.SettlementOrderMany["order_amount"];
  settlementAmount: bnplMerchants.SettlementOrderMany["settlement_amount"];
  mdr: bnplMerchants.SettlementOrderMany["mdr"];
  commissionAmount: bnplMerchants.SettlementOrderMany["commission_amount"];
  // @ts-ignore Update api types
  orderNumber: bnplMerchants.SettlementOrderMany["order_number"];
};

type GetSettlementOrdersParams = {
  settlementId?: string;
};

export const getSettlemenOrders = async ({
  settlementId,
}: GetSettlementOrdersParams): Promise<GetSettlementOrdersResponseData[]> => {
  const response =
    await bnplMerchants.getApiMerchantsServiceSettlementsIdOrders(
      settlementId!,
    );

  if (!response.data.data) {
    throw new Error("Error getting settlement orders");
  }

  return (
    // @TODO Add orderNumber after backend update
    response.data.data.items?.map((order) => ({
      id: order.id,
      created: order.created,
      updated: order.updated,
      archived: order.archived,
      settlementId: order.settlement_id,
      orderId: order.order_id,
      orderAmount: order.order_amount,
      settlementAmount: order.settlement_amount,
      mdr: order.mdr,
      commissionAmount: order.commission_amount,
      // @ts-ignore Update api types
      orderNumber: order.order_number,
    })) || []
  );
};
