import { Typography } from "shared/ui";
import { LayoutMain } from "widgets/layout-main";

export const PageAccessDenied = () => {
  return (
    <LayoutMain>
      <Typography align="center" variant="headlineL">
        No access
      </Typography>
      <Typography align="center" color="neutral.50" mt={1}>
        If you still have questions, please contact {/* @TODO To config */}
        <Typography color="primary">support@ahapay.com</Typography>
      </Typography>
    </LayoutMain>
  );
};
