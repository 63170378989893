import { IconClose } from "@mychili/ui-web";
import {
  GetSettlementOrdersResponseData,
  GetSettlementsResponseData,
} from "shared/api";
import { formatDate } from "shared/lib";
import {
  Box,
  Grid,
  GridColumn,
  IconButton,
  Stack,
  Typography,
} from "shared/ui";

type SettlementDetailedView = {
  onClose: () => void;
  settlement?: GetSettlementsResponseData;
  orders?: GetSettlementOrdersResponseData[];
};

const ORDERS_GRID_COLUMNS: GridColumn<GetSettlementOrdersResponseData>[] = [
  {
    key: "orderNumber",
    title: "ID",
  },
  {
    key: "orderAmount",
    title: "Amount",
  },
  {
    key: "commissionAmount",
    title: "Commission Amount",
  },
  {
    key: "settlementAmount",
    title: "Settlements Amount",
  },
  {
    key: "mdr",
    title: "MDR",
  },
];

const DetailedViewItem = ({
  label,
  value,
}: {
  label: string;
  value?: string | number;
}) => (
  <Stack direction="row">
    <Typography fontWeight={600} color="primary" variant="body16">
      {label}:
    </Typography>
    <Typography ml={1} variant="body16">
      {value}
    </Typography>
  </Stack>
);

export const SettlementDetailedView = ({
  onClose,
  settlement,
  orders,
}: SettlementDetailedView) => {
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="headlineL">
          Settlement ID {settlement?.settlementNumber}
        </Typography>
        <IconButton onClick={onClose}>
          <IconClose />
        </IconButton>
      </Stack>

      <Box
        display="grid"
        gridTemplateColumns={{ xs: "none", md: "repeat(3, 1fr)" }}
      >
        <DetailedViewItem label="Merchant" value={settlement?.merchantName} />
        <DetailedViewItem
          label="Payment date"
          value={formatDate(settlement?.paymentDate, "DD.MM.YYYY")}
        />
        <DetailedViewItem
          label="Created date"
          value={formatDate(settlement?.created, "DD.MM.YYYY")}
        />
        <DetailedViewItem label="Status" value={settlement?.status} />
        <DetailedViewItem label="Amount" value={settlement?.salesAmount} />
      </Box>

      <Typography variant="headlineL" mt={3}>
        Orders
      </Typography>

      <Grid columns={ORDERS_GRID_COLUMNS} data={orders} />
    </>
  );
};
