import { useQuery } from "@tanstack/react-query";
import { getEmployeeOutlets } from "shared/api";

import { QUERY_KEY_EMPLOYEE_OUTLETS } from "../../lib";

export const useEmployeeOutlets = () =>
  useQuery({
    queryFn: getEmployeeOutlets,
    queryKey: QUERY_KEY_EMPLOYEE_OUTLETS,
  });
