import { format as formatCurrency, formatDate } from "shared/lib";

import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "..";
import { GridColumn } from "./types";

type GridProps<T> = {
  columns: GridColumn<T>[];
  data?: T[];
  dateFormat?: string;
  noDataMessage?: string;
  onRowSelect?: (row: T) => void;
  selectedRow?: T | undefined;
};

export const Grid = <T extends { id: string | undefined }>({
  columns,
  data,
  dateFormat = "DD.MM.YYYY",
  noDataMessage = "Data is empty",
  selectedRow,
  onRowSelect,
}: GridProps<T>) => {
  const renderCell = (
    value: T[keyof T],
    columnRender: GridColumn<T>["render"],
  ): string => {
    if (columnRender === "date" && value instanceof Date)
      return formatDate(value, dateFormat);

    if (typeof columnRender === "function") return columnRender(value);

    if (columnRender === "currency") return formatCurrency(Number(value));

    return value ? String(value) : "";
  };

  return (
    <Stack
      direction="row"
      sx={{
        ".MuiTable-root .Mui-selected": {
          backgroundColor: "common.surface2",
        },
        ".MuiTable-root .Mui-selected:hover": {
          backgroundColor: "common.surface2",
        },
        ".MuiTable-root .Mui-selected .MuiTableCell-root": {
          color: "common.primary",
        },
      }}
    >
      {data && data.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(({ key, title }) => (
                  <TableCell key={String(key)}>
                    <Typography fontWeight={700}>{title}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    selected={selectedRow?.id === row.id}
                    onClick={onRowSelect ? () => onRowSelect(row) : undefined}
                    sx={{
                      cursor: onRowSelect ? "pointer" : "default",
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={`${String(column.key)}-${row.id}`}
                        align={column.align ?? "left"}
                      >
                        {renderCell(row[column.key], column.render)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography
          align="center"
          color="common.surface4"
          variant="headlineM"
          marginTop="10%"
          marginX="auto"
        >
          {noDataMessage}
        </Typography>
      )}
    </Stack>
  );
};
