import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetSettlementsResponseData = {
  id: bnplMerchants.GetSettlementsMany["id"];
  created?: Date | undefined;
  updated?: Date | undefined;
  archived: bnplMerchants.GetSettlementsMany["archived"];
  merchantId: bnplMerchants.GetSettlementsMany["merchant_id"];
  agreementId: bnplMerchants.GetSettlementsMany["agreement_id"];
  settlementNumber: bnplMerchants.GetSettlementsMany["settlement_number"];
  settlementAmount: bnplMerchants.GetSettlementsMany["settlement_amount"];
  salesAmount: bnplMerchants.GetSettlementsMany["sales_amount"];
  status: bnplMerchants.GetSettlementsMany["status"];
  ordersCount: bnplMerchants.GetSettlementsMany["orders_count"];
  settlementDate?: Date | undefined;
  paymentDate?: Date | undefined;
  lastUpdatedUser: bnplMerchants.GetSettlementsMany["last_updated_user"];
  merchantName: bnplMerchants.GetSettlementsMany["merchant_name"];
};

export const getSettlements = async (): Promise<
  GetSettlementsResponseData[]
> => {
  const response = await bnplMerchants.getApiMerchantsServiceSettlements();

  if (!response.data.data) {
    throw new Error("Error getting settlements");
  }

  return (
    response.data.data.items?.map((settlement) => ({
      id: settlement.id,
      created: settlement.created ? new Date(settlement.created) : undefined,
      updated: settlement.updated ? new Date(settlement.updated) : undefined,
      archived: settlement.archived,
      merchantId: settlement.merchant_id,
      agreementId: settlement.agreement_id,
      settlementNumber: settlement.settlement_number,
      settlementAmount: settlement.settlement_amount,
      salesAmount: settlement.sales_amount,
      status: settlement.status,
      ordersCount: settlement.orders_count,
      settlementDate: settlement.settlement_date
        ? new Date(settlement.settlement_date)
        : undefined,
      paymentDate: settlement.payment_date
        ? new Date(settlement.payment_date)
        : undefined,
      lastUpdatedUser: settlement.last_updated_user,
      merchantName: settlement.merchant_name,
    })) || []
  );
};
