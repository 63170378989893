import { ProfileForm } from "features/update-profile";
import { Box, Typography } from "shared/ui";
import { LayoutMain } from "widgets/layout-main";

export const PageProfile = () => {
  return (
    <LayoutMain>
      <Typography variant="title5">Profile</Typography>

      <Box width={{ xs: "100%", md: "500px" }} mt={5}>
        <ProfileForm />
      </Box>
    </LayoutMain>
  );
};
