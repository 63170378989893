import { useBoolean } from "@mychili/ui-web";
import { useEmployeeOutlets } from "entities/employee-outlets";
import { useEmployees } from "entities/employees";
import { getOrdersPath } from "entities/orders";
import { useTradeOutletsOptions } from "entities/trade-outlets";
import { useCreateEmployee } from "features/create-employee";
import { useDeleteEmployee } from "features/delete-employee";
import {
  EmployeeForm,
  EmployeeFormDataWithTradeOutlets,
  useUpdateEmployee,
} from "features/update-employee";
import { useUpdateEmployeeOutlets } from "features/update-employee-outlets";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  parsePhoneNumber,
  showSnackbarSuccess,
  useIsDesktop,
} from "shared/lib";
import {
  Button,
  ConfirmDialog,
  Drawer,
  Grid,
  Stack,
  Typography,
} from "shared/ui";

import { EmployeeWithOutlets, mapEmployeesToOutlets } from "..";
import {
  COLUMNS,
  getEmployeeOutletsPayload,
  handleEmployeeMutationError,
} from "../lib";

export const EmployeeList = () => {
  const [selectedItem, setSelectedItem] = useState<EmployeeWithOutlets>();

  const isAddingNewRecord = useBoolean(false);
  const confirmDialog = useBoolean();

  const isDesktop = useIsDesktop();

  const { data: employees } = useEmployees();
  const { data: employeeOutlets } = useEmployeeOutlets();
  const { tradeOutletsOptions } = useTradeOutletsOptions();

  const createEmployee = useCreateEmployee();
  const updateEmployee = useUpdateEmployee();
  const updateEmployeeOutlets = useUpdateEmployeeOutlets();
  const deleteEmployee = useDeleteEmployee();

  const isTableVisible = isDesktop || (!isAddingNewRecord && !selectedItem);

  const employeesWithStores = mapEmployeesToOutlets(
    employees!,
    tradeOutletsOptions,
    employeeOutlets!,
  );

  const handleFormSubmit = (formData: EmployeeFormDataWithTradeOutlets) => {
    if (isAddingNewRecord.value) {
      createEmployee.mutate(
        {
          payload: {
            name: formData.name,
            role: formData.role,
            phone: parsePhoneNumber(formData.phone),
            email: formData.email,
          },
        },
        {
          onError: handleEmployeeMutationError,
          onSuccess: ({ id }) => {
            if (formData.tradeOutletsIds) {
              const updateOutletsPayload = getEmployeeOutletsPayload(
                formData.tradeOutletsIds,
              );

              showSnackbarSuccess("Employee was successfully created");

              updateEmployeeOutlets.mutate(
                {
                  employeeId: id!,
                  bindIds: updateOutletsPayload.bindIds,
                },
                { onSuccess: isAddingNewRecord.off },
              );
            }
          },
        },
      );
    } else {
      updateEmployee.mutate(
        {
          employeeId: selectedItem?.id!,
          payload: {
            name: formData.name,
            role: formData.role,
            email: formData.email,
          },
        },
        {
          onError: handleEmployeeMutationError,
          onSuccess: async () => {
            const updateOutletsPayload = getEmployeeOutletsPayload(
              formData.tradeOutletsIds,
            );

            showSnackbarSuccess("Employee successfully updated");

            updateEmployeeOutlets.mutate({
              employeeId: selectedItem?.id!,
              bindIds: updateOutletsPayload.bindIds,
              detachIds: updateOutletsPayload.detachIds,
            });
          },
        },
      );
    }
  };

  const handleDelete = () => {
    deleteEmployee.mutate(
      {
        employeeId: selectedItem?.id!,
      },
      {
        onSuccess: handleUpdateFormClose,
      },
    );
  };

  const handleEmployeeFormClose = () => {
    setSelectedItem(undefined);
    isAddingNewRecord.off();
  };

  const handleUpdateFormClose = () => setSelectedItem(undefined);

  const handleRowSelect = (row: EmployeeWithOutlets) => {
    setSelectedItem(row);
  };

  useEffect(() => {
    if (employeesWithStores.length > 0) {
      setSelectedItem(employeesWithStores[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeesWithStores.length]);

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ mb: isTableVisible ? 0 : 2 }}
      >
        <Typography variant="title5">Employees</Typography>
        <Button variant="outlined" onClick={isAddingNewRecord.on}>
          +Add
        </Button>
      </Stack>

      <Grid
        columns={COLUMNS}
        data={employeesWithStores}
        selectedRow={selectedItem}
        onRowSelect={handleRowSelect}
        noDataMessage="You don't have any employees yet"
      />

      <Drawer
        anchor="right"
        open={isAddingNewRecord.value || Boolean(selectedItem)}
        onClose={
          isAddingNewRecord.value
            ? isAddingNewRecord.off
            : handleUpdateFormClose
        }
        sx={{
          ".MuiDrawer-paper": {
            width: isDesktop ? 600 : "100%",
          },
        }}
      >
        <Stack p={2} gap={1}>
          <EmployeeForm
            initialData={selectedItem}
            onCancelClick={handleEmployeeFormClose}
            onDeleteClick={confirmDialog.on}
            onSubmit={handleFormSubmit}
            tradeOutletsOptions={tradeOutletsOptions}
          />

          <Link to={getOrdersPath({ employeeId: selectedItem?.id })}>
            <Typography color="black">
              See all orders of this employee...
            </Typography>
          </Link>
        </Stack>
      </Drawer>

      <ConfirmDialog
        onClose={confirmDialog.off}
        onConfirm={handleDelete}
        isOpen={confirmDialog.value}
        title={`Delete employee ${selectedItem?.name}?`}
      />
    </>
  );
};
