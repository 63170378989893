import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { enqueueSnackbar } from "@mychili/ui-web";

type UpdateEmployeeOutletsParams = {
  employeeId: bnplMerchants.BindEmployeeOutlets["employee_id"];
  bindIds?: bnplMerchants.BindEmployeeOutlets["bind_trade_outlet_ids"];
  detachIds?: bnplMerchants.BindEmployeeOutlets["detach_trade_outlet_ids"];
};

export const updateEmployeeOutlets = async ({
  employeeId,
  bindIds,
  detachIds,
}: UpdateEmployeeOutletsParams): Promise<boolean> => {
  const response =
    await bnplMerchants.postApiMerchantsServiceEmployeeOutletsBind({
      employee_id: employeeId,
      bind_trade_outlet_ids: bindIds,
      detach_trade_outlet_ids: detachIds,
    });

  if (!response.data.data) {
    const errorMessage = "Error binding employee outlets";
    enqueueSnackbar(errorMessage, { variant: "error" });
    throw new Error(errorMessage);
  }

  return Boolean(response.data.data.success);
};
