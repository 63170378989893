import { ColumnDef } from "@tanstack/react-table";
import { GetOrdersItem } from "shared/api";
import { formatDate } from "shared/lib";
import { GridColumn, SortingOptions } from "shared/ui";

export const COLUMNS: GridColumn<GetOrdersItem>[] = [
  { key: "created", title: "Date", render: "date" },
  { key: "orderNumber", title: "ID" },
  { key: "status", title: "Status" },
  { key: "tradeOutletName", title: "Store" },
  { key: "employeeName", title: "Employee" },
  { key: "amount", title: "Amount", render: "currency", align: "right" },
];

export const COLUMNS_V2: ColumnDef<GetOrdersItem, any>[] = [
  {
    accessorKey: "created",
    header: "Date",
    cell: (cell) => {
      const value = cell.getValue();
      return value instanceof Date ? formatDate(value, "DD.MM.YYYY") : "";
    },
  },
  {
    accessorKey: "orderNumber",
    header: "ID",
  },
  {
    accessorKey: "status",
    header: "Status",
  },
  {
    accessorKey: "tradeOutletName",
    header: "Store",
  },
  {
    accessorKey: "employeeName",
    header: "Employee",
  },
  {
    accessorKey: "amount",
    header: "Amount",
  },
];

// @TODO
export const SORTING_OPTIONS: SortingOptions<GetOrdersItem> = {
  created: [
    {
      label: "Newest first",
      value: "desc",
    },
    {
      label: "Oldest first",
      value: "asc",
    },
  ],
  status: [
    {
      label: "From paid to canceled",
      value: "paid",
    },
    {
      label: "From canceled to paid",
      value: "canceled",
    },
  ],
};
