import { useQuery } from "@tanstack/react-query";
import { getEmployee } from "shared/api";

import { QUERY_KEY_PROFILE } from "../../lib";

export const useProfile = () =>
  useQuery({
    queryFn: getEmployee,
    queryKey: QUERY_KEY_PROFILE,
  });
