import { EmployeesOption } from "entities/employees";
import { TradeOutletsOption } from "entities/trade-outlets";
import { GetOrdersItem } from "shared/api";
import { Box, SelectChangeEvent, Stack } from "shared/ui";

import { FilterTradeOutlet } from ".";
import { FilterDate } from "./filter-date";
import { FilterEmployee } from "./filter-employee";
import { FilterStatus } from "./filter-status";

export type FilterOrdersState = {
  date?: Date[];
  employeeId?: string;
  tradeOutletId?: GetOrdersItem["tradeOutletId"];
  status?: GetOrdersItem["status"];
};

type FilterOrdersProps = {
  data: FilterOrdersState;
  onChange: (field: string, value: any) => void;
  tradeOutletOptions: TradeOutletsOption[];
  employeeOptions: EmployeesOption[];
};

export const FilterOrders = ({
  data,
  onChange,
  tradeOutletOptions,
  employeeOptions,
}: FilterOrdersProps) => {
  const handleTradeOutletChange = (e: SelectChangeEvent<unknown>) => {
    onChange("tradeOutletId", e.target.value);
  };

  const handleStatusChange = (e: SelectChangeEvent<unknown>) => {
    onChange("status", e.target.value);
  };

  const handleDateChange = (value: Date | Date[] | undefined) => {
    onChange("date", value);
  };

  const handleEmployeeChange = (e: SelectChangeEvent<unknown>) => {
    onChange("employeeId", e.target.value);
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Box flexBasis="150px">
        <FilterTradeOutlet
          value={data.tradeOutletId}
          onChange={handleTradeOutletChange}
          options={tradeOutletOptions}
        />
      </Box>

      <Box flexBasis="150px">
        <FilterStatus value={data.status} onChange={handleStatusChange} />
      </Box>

      <Box flexBasis="300px">
        <FilterDate value={data.date} onChange={handleDateChange} />
      </Box>

      <Box flexBasis="150px">
        <FilterEmployee
          value={data.employeeId}
          onChange={handleEmployeeChange}
          options={employeeOptions}
        />
      </Box>
    </Stack>
  );
};
