import { Tooltip, TooltipProps, Typography } from "@mychili/ui-web";

export const BaseTooltip = (props: TooltipProps) => (
  <Tooltip
    {...props}
    title={
      <Typography variant="smallTextRegular" color="common.white">
        {props.title}
      </Typography>
    }
    componentsProps={{
      arrow: {
        sx: {
          color: "neutral.20",
        },
      },
      tooltip: {
        sx: {
          backgroundColor: "neutral.20",
          py: 1,
          px: 1.75,
        },
      },
    }}
    placement="left"
    arrow
  />
);
