import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetOrdersItem = {
  id: bnplMerchants.GetOrdersMany["id"];
  created: Date | undefined;
  updated: bnplMerchants.GetOrdersMany["updated"];
  archived: bnplMerchants.GetOrdersMany["archived"];
  tradeOutletId: bnplMerchants.GetOrdersMany["trade_outlet_id"];
  clientId: bnplMerchants.GetOrdersMany["client_id"];
  applicationId: bnplMerchants.GetOrdersMany["application_id"];
  contractId: bnplMerchants.GetOrdersMany["contract_id"];
  calcId: bnplMerchants.GetOrdersMany["calc_id"];
  offerId: bnplMerchants.GetOrdersMany["offer_id"];
  amount: bnplMerchants.GetOrdersMany["amount"];
  downPayment: bnplMerchants.GetOrdersMany["down_payment"];
  status: bnplMerchants.GetOrdersMany["status"];
  orderNumber: bnplMerchants.GetOrdersMany["order_number"];
  link: bnplMerchants.GetOrdersMany["link"];
  cardId: bnplMerchants.GetOrdersMany["card_id"];
  merchantId: bnplMerchants.GetOrdersMany["merchant_id"];
  tradeOutletName: bnplMerchants.GetOrdersMany["trade_outlet_name"];
  employeeName: bnplMerchants.GetOrdersMany["employee_name"];
  employeeId: bnplMerchants.GetOrdersMany["employee_id"];
};

export type GetOrdersResponseData = {
  items: GetOrdersItem[] | undefined;
  pagination: bnplMerchants.Pagination | undefined;
};

export const getOrders = async (
  options: bnplMerchants.GetApiMerchantsServiceOrdersParams,
): Promise<GetOrdersResponseData> => {
  const response = await bnplMerchants.getApiMerchantsServiceOrders(options);

  return {
    items: response.data.data?.items?.map((o) => ({
      id: o.id,
      created: o.created ? new Date(o.created) : undefined,
      updated: o.updated,
      archived: o.archived,
      tradeOutletId: o.trade_outlet_id,
      clientId: o.client_id,
      applicationId: o.application_id,
      contractId: o.contract_id,
      calcId: o.calc_id,
      offerId: o.offer_id,
      amount: o.amount,
      downPayment: o.down_payment,
      status: o.status,
      orderNumber: o.order_number,
      link: o.link,
      cardId: o.card_id,
      merchantId: o.merchant_id,
      tradeOutletName: o.trade_outlet_name,
      employeeName: o.employee_name,
      employeeId: o.employee_id,
    })),
    pagination: response.data.data?.pagination,
  };
};
