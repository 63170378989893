import { GetOrdersItem } from "shared/api";
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "shared/ui";

type FilterStatusProps = {
  onChange: (e: SelectChangeEvent<unknown>) => void;
  value?: GetOrdersItem["status"];
};

const OPTIONS: { label: string; value: GetOrdersItem["status"] }[] = [
  { value: "PENDING", label: "Pending" },
  { value: "PROCESSING", label: "Processing" },
  { value: "CANCELLED", label: "Cancelled" },
  { value: "EXPIRED", label: "Expired" },
  { value: "ACTIVE", label: "Active" },
  { value: "REJECTED", label: "Rejected" },
  { value: "CONFIRMED", label: "Confirmed" },
  { value: "REFUNDED", label: "Refunded" },
  { value: "REPAID", label: "Repaid" },
];

export const FilterStatus = ({ value, onChange }: FilterStatusProps) => {
  return (
    <Box flex={1}>
      <Select
        displayEmpty
        value={value}
        onChange={onChange}
        size="small"
        renderValue={(id: unknown) => {
          if (id)
            return <div>{OPTIONS.find((o) => o.value === id)?.label}</div>;

          return <Typography color="common.surface4">Status</Typography>;
        }}
        variant="filled"
      >
        <MenuItem>
          <em>Show All</em>
        </MenuItem>
        {OPTIONS.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
