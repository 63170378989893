import { useTradeOutlets } from "..";

export type TradeOutletsOption = {
  value: string;
  label: string;
};

export const useTradeOutletsOptions = () => {
  const query = useTradeOutlets();

  return {
    ...query,
    tradeOutletsOptions:
      query.data?.map((i) => ({
        value: i.id!,
        label: i.name!,
      })) || [],
  };
};
