import { getOrdersPath } from "entities/orders";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  GetAgreementsResponseData,
  GetTradeOutletsResponseData,
} from "shared/api";
import {
  Box,
  Button,
  Input,
  MenuItem,
  Select,
  Show,
  Stack,
  Typography,
} from "shared/ui";

export type TradeOutletFormData = {
  agreementId: string;
  name: string;
  locationGeo: string | null | undefined;
};

type TradeOutletFormProps = {
  onCancelClick: () => void;
  onDeleteClick: () => void;
  onSubmit: (formData: TradeOutletFormData) => void;
  initialData?: GetTradeOutletsResponseData;
  agreements: GetAgreementsResponseData[];
};

export const TradeOutletForm = ({
  initialData,
  onCancelClick,
  onDeleteClick,
  onSubmit,
  agreements,
}: TradeOutletFormProps) => {
  const { handleSubmit, register, reset, control } =
    useForm<TradeOutletFormData>();

  useEffect(() => {
    if (initialData) {
      reset({
        name: initialData.name,
        locationGeo: initialData.locationGeo,
      });
    }
  }, [initialData, reset]);

  return (
    <Stack spacing={2}>
      <Typography variant="bigTextSemiBold">
        {initialData ? "Updating outlet" : "Adding new outlet"}
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Box>
            <Typography flex={1}>Name</Typography>
            <Box flex={1}>
              <Input {...register("name")} required />
            </Box>
          </Box>

          <Box>
            <Typography flex={1}>Location</Typography>
            <Box flex={1}>
              <Input {...register("locationGeo")} required />
            </Box>
          </Box>

          <Box>
            <Typography flex={1}>Agreement</Typography>
            <Box flex={1}>
              <Controller
                control={control}
                name="agreementId"
                render={({ field }) => (
                  <Select {...field} variant="filled" required>
                    {agreements.map(({ id, agreementNumber }) => (
                      <MenuItem value={id}>{agreementNumber}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </Box>

          <Stack direction="row" spacing={2}>
            <Button type="submit">{initialData ? "Update" : "Add"}</Button>
            <Show when={Boolean(initialData)}>
              <Button variant="outlined" onClick={onDeleteClick}>
                Delete
              </Button>
            </Show>
            <Button variant="outlined" onClick={onCancelClick}>
              Cancel
            </Button>
          </Stack>

          <Show when={Boolean(initialData)}>
            <Link to={getOrdersPath({ tradeOutletId: initialData?.id! })}>
              <Typography color="black">
                See all orders in this place...
              </Typography>
            </Link>
          </Show>
        </Stack>
      </form>
    </Stack>
  );
};
