import { GetSettlementsResponseData } from "shared/api";
import { GridColumn } from "shared/ui";

export const COLUMNS: GridColumn<GetSettlementsResponseData>[] = [
  {
    key: "created",
    title: "Date",
    render: "date",
  },
  {
    key: "status",
    title: "State",
  },
  {
    key: "settlementNumber",
    title: "ID",
  },
  {
    key: "ordersCount",
    title: "Orders count",
  },
  {
    key: "salesAmount",
    title: "Amount",
  },
];
