import { LayoutMain } from "widgets/layout-main";
import { OrderList } from "widgets/order-list";
// import { OrderListV2 } from "widgets/order-list/ui/order-list-v2";

export const PageOrders = () => {
  return (
    <LayoutMain>
      <OrderList />
      {/* <OrderListV2 /> */}
    </LayoutMain>
  );
};
