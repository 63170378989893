import { showSnackbarError } from "shared/lib";

// @TODO Remove ts-ignore after typing backend errors
export const handleEmployeeMutationError = (error: Error) => {
  // @ts-ignore
  const emailError = error?.response?.data?.error?.errors?.email?.[0];
  // @ts-ignore
  const phoneError = error?.response?.data?.error?.errors?.phone?.[0];

  // @ts-ignore
  const clientError = error?.response?.data?.error?.message;

  if (clientError) {
    showSnackbarError(clientError);
  }

  if (emailError) {
    showSnackbarError(emailError);
  }

  if (phoneError) {
    showSnackbarError(phoneError);
  }
};
