import { PageAccessDenied } from "pages/access-denied";
import { PageEmployees } from "pages/employees";
import { PageLogin } from "pages/login";
import { PageMain } from "pages/main";
import { PageOrders } from "pages/orders";
import { PageProfile } from "pages/profile";
import { PageProtected, protectedPageLoader } from "pages/protected";
import { PageSettlements } from "pages/settlements";
import { PageTradeOutlets } from "pages/trade-outlets";
import { PageWelcome } from "pages/welcome";
import {
  createBrowserRouter,
  RouterProvider as BaseRouterProvider,
} from "react-router-dom";
import { ROUTES } from "shared/routing";

export const RouterProvider = () => {
  const router = createBrowserRouter([
    {
      element: <PageLogin />,
      path: ROUTES.login,
    },
    {
      element: <PageWelcome />,
      path: ROUTES.welcome,
    },
    {
      element: <PageAccessDenied />,
      path: ROUTES.accessDenied,
    },
    {
      element: <PageProtected />,
      loader: protectedPageLoader,
      path: ROUTES.main,
      children: [
        {
          element: <PageMain />,
          path: ROUTES.main,
        },
        {
          element: <PageTradeOutlets />,
          path: ROUTES.tradeOutlets,
        },
        {
          element: <PageEmployees />,
          path: ROUTES.employees,
        },
        {
          element: <PageOrders />,
          path: ROUTES.orders,
        },
        {
          element: <PageSettlements />,
          path: ROUTES.settlements,
        },
        {
          element: <PageProfile />,
          path: ROUTES.profile,
        },
      ],
    },
  ]);

  return <BaseRouterProvider router={router} />;
};
