import { TradeOutletsOption } from "entities/trade-outlets";
import { GridColumn } from "shared/ui";

import { EmployeeWithOutlets } from ".";

export const COLUMNS: GridColumn<EmployeeWithOutlets>[] = [
  { key: "name", title: "Name" },
  { key: "phone", title: "Phone" },
  { key: "email", title: "Email" },
  {
    key: "outlets",
    title: "Access",
    render: (cellValue: string | boolean | TradeOutletsOption[] | undefined) =>
      Array.isArray(cellValue)
        ? cellValue.map((outlet) => outlet.label).join(", ")
        : "",
  },
];
