import { useQuery } from "@tanstack/react-query";
import { getTradeOutlets } from "shared/api";

import { QUERY_KEY_TRADE_OUTLETS } from "../../lib";

export const useTradeOutlets = () =>
  useQuery({
    queryFn: getTradeOutlets,
    queryKey: QUERY_KEY_TRADE_OUTLETS,
  });
