import { ROUTES } from "shared/routing";

type Params = {
  employeeId?: string;
  tradeOutletId?: string;
};

export const getOrdersPath = (params: Params): string =>
  Object.entries(params).reduce(
    (res, [key, value]) => `${res}?${key}=${value}`,
    String(ROUTES.orders),
  );
