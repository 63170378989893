import { TradeOutletsOption } from "entities/trade-outlets";

export type TradeOutletsIdsState = {
  [id: string]: {
    label: string;
    value: string;
    checked: boolean;
  };
};

export const getTradeOutletsIdsState = (
  options: TradeOutletsOption[],
  employeesOutlets: TradeOutletsOption[],
): TradeOutletsIdsState =>
  options.reduce((acc, option) => {
    return {
      ...acc,
      [option.value]: {
        checked: employeesOutlets.some((item) => item.value === option.value),
        value: option.value,
        label: option.label,
      },
    };
  }, {});
