import { Show } from "@mychili/ui-web";
import { useEmployeesOptions } from "entities/employees";
import { useOrders } from "entities/orders";
import { useTradeOutletsOptions } from "entities/trade-outlets";
import { FilterOrders, FilterOrdersState } from "features/filter-orders";
import {
  OrderForm,
  OrderFormData,
  useUpdateOrder,
} from "features/update-order";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GetOrdersItem } from "shared/api";
import {
  format as formatCurrency,
  getSearchParamsObject,
  showSnackbarError,
  showSnackbarSuccess,
  useIsDesktop,
} from "shared/lib";
import { Drawer, Grid, Stack, Typography } from "shared/ui";

import { COLUMNS, getFilteredOrders } from "../lib";

export const OrderList = () => {
  const [searchParams] = useSearchParams();

  const [selectedItem, setSelectedItem] = useState<GetOrdersItem>();

  const isDesktop = useIsDesktop();

  const orders = useOrders();
  const [filterState, setFilterState] = useState<FilterOrdersState>(
    getSearchParamsObject(searchParams),
  );

  const isOrdersListEmpty = orders.data?.items?.length === 0;

  const updateOrder = useUpdateOrder();

  const { tradeOutletsOptions } = useTradeOutletsOptions();
  const { employeesOptions } = useEmployeesOptions();

  const handleFormSubmit = (formData: OrderFormData) => {
    updateOrder.mutate(
      {
        orderId: selectedItem!.id!,
        payload: {
          amount: formData.amount,
        },
      },
      {
        onSuccess: () => {
          showSnackbarSuccess("Order was successfully updated");
        },
        onError: (error) => {
          // @TODO Fix ts-ignore
          // @ts-ignore
          const errorMessage = error?.response?.data?.error?.message;

          if (errorMessage) {
            showSnackbarError(errorMessage);
          }
        },
      },
    );
  };

  const handleFormClose = () => {
    setSelectedItem(undefined);
  };

  const handleFilterChange = (field: string, value: any) => {
    setFilterState((currentState) => ({ ...currentState, [field]: value }));
  };

  const handleRowSelect = (row: GetOrdersItem) => {
    setSelectedItem(row);
  };

  const filteredOrders = getFilteredOrders(filterState, orders.data?.items);

  const totalAmount = filteredOrders.reduce((total, { amount }) => {
    return total + amount;
  }, 0);

  return (
    <>
      <Typography variant="title5">Orders</Typography>

      <Show when={!isOrdersListEmpty}>
        <Stack py={2} spacing={2}>
          <FilterOrders
            data={filterState}
            onChange={handleFilterChange}
            tradeOutletOptions={tradeOutletsOptions}
            employeeOptions={employeesOptions}
          />
        </Stack>
      </Show>

      <Grid
        columns={COLUMNS}
        data={filteredOrders}
        noDataMessage="You don't have any orders yet"
        selectedRow={selectedItem}
        onRowSelect={handleRowSelect}
      />

      <Show when={!isOrdersListEmpty}>
        <Stack alignItems="flex-end" mt={1} pr={2}>
          <Typography variant="headlineM">
            Total: {formatCurrency(totalAmount)}
          </Typography>
        </Stack>
      </Show>

      <Drawer
        anchor="right"
        open={Boolean(selectedItem)}
        onClose={handleFormClose}
        sx={{
          ".MuiDrawer-paper": {
            width: isDesktop ? 600 : "100%",
          },
        }}
      >
        <Stack p={2}>
          <OrderForm
            initialData={selectedItem}
            onCancelClick={handleFormClose}
            onSubmit={handleFormSubmit}
            tradeOutletsOptions={tradeOutletsOptions}
          />
        </Stack>
      </Drawer>
    </>
  );
};
