import { TradeOutletsOption } from "entities/trade-outlets";
import {
  GetEmployeeOutletsResponseData,
  GetEmployeesResponseData,
} from "shared/api";

export interface EmployeeWithOutlets extends GetEmployeesResponseData {
  outlets: TradeOutletsOption[];
}

export const mapEmployeesToOutlets = (
  employees?: GetEmployeesResponseData[],
  tradeOutlets?: TradeOutletsOption[],
  employeesOutlets?: GetEmployeeOutletsResponseData[],
): EmployeeWithOutlets[] => {
  if (
    !employees ||
    !tradeOutlets ||
    tradeOutlets.length === 0 ||
    !employeesOutlets
  )
    return [];

  const tradeOutletsDictionary: { [id: string]: TradeOutletsOption } =
    tradeOutlets.reduce((acc, tradeOutlet) => {
      return {
        ...acc,
        [tradeOutlet.value]: tradeOutlet,
      };
    }, {});

  return employees.map((employee) => {
    return {
      ...employee,
      outlets: employeesOutlets
        .filter((employeeOutlet) => employeeOutlet.employeeId === employee.id)
        .map(
          (employeeOutlet) =>
            tradeOutletsDictionary[employeeOutlet.tradeOutletId],
        ),
    };
  });
};
