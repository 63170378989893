import { TradeOutletsOption } from "entities/trade-outlets";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { GetEmployeesResponseData } from "shared/api";
import { COUNTRY_CODE } from "shared/config";
import { prefixInternational } from "shared/lib/phones/parse";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Input,
  InputPhone,
  MenuItem,
  Select,
  Show,
  Stack,
  Typography,
} from "shared/ui";
import { EmployeeWithOutlets } from "widgets/employee-list";

import { getTradeOutletsIdsState, TradeOutletsIdsState } from "../lib";

export type EmployeeFormDataWithTradeOutlets = EmployeeFormData & {
  tradeOutletsIds: TradeOutletsIdsState;
};

type EmployeeFormData = {
  name: string;
  phone: string;
  email: string;
  role: GetEmployeesResponseData["role"];
};

type EmployeeFormProps = {
  onDeleteClick: () => void;
  onCancelClick: () => void;
  onSubmit: (formData: EmployeeFormDataWithTradeOutlets) => void;
  tradeOutletsOptions: TradeOutletsOption[];
  initialData?: EmployeeWithOutlets;
};

export const EmployeeForm = ({
  onCancelClick,
  onDeleteClick,
  onSubmit,
  initialData,
  tradeOutletsOptions,
}: EmployeeFormProps) => {
  const { register, handleSubmit, control, reset } = useForm<EmployeeFormData>({
    defaultValues: initialData,
  });

  const [tradeOutletsState, setTradeOutletsState] =
    useState<TradeOutletsIdsState>({});

  const handleCheckboxChange = (id: string, value: boolean) => {
    setTradeOutletsState((currentState) => ({
      ...currentState,
      [id]: {
        ...currentState[id],
        checked: value,
      },
    }));
  };

  const handleFormSubmit = (formData: any) => {
    onSubmit({ ...formData, tradeOutletsIds: tradeOutletsState });
  };

  useEffect(() => {
    if (initialData) {
      reset({
        ...initialData,
        phone: initialData.phone.replace(prefixInternational, ""),
      });
      setTradeOutletsState(
        getTradeOutletsIdsState(tradeOutletsOptions, initialData.outlets),
      );
    } else {
      setTradeOutletsState(getTradeOutletsIdsState(tradeOutletsOptions, []));
    }
  }, [initialData, tradeOutletsOptions, reset]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing={2}>
        <Box>
          <Typography flex={1}>Name</Typography>
          <Box flex={1}>
            <Input {...register("name")} required />
          </Box>
        </Box>

        <Box>
          <Typography flex={1}>Phone</Typography>
          <Box flex={1}>
            <InputPhone
              countryCode={COUNTRY_CODE}
              inputProps={{ type: "tel" }}
              {...register("phone")}
              required
              disabled={Boolean(initialData)}
            />
          </Box>
        </Box>

        <Box>
          <Typography flex={1}>Email</Typography>
          <Box flex={1}>
            <Input
              {...register("email")}
              required
              disabled={Boolean(initialData)}
            />
          </Box>
        </Box>

        <Box>
          <Typography flex={1}>Role</Typography>
          <Box flex={1}>
            <Controller
              control={control}
              name="role"
              render={({ field }) => (
                <Select {...field} variant="filled" required>
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="employee">Employee</MenuItem>
                </Select>
              )}
            />
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle1">Stores</Typography>
          {Object.values(tradeOutletsState).map((o) => (
            <Stack direction="row" alignItems="center" key={o.value}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={o.checked}
                    onChange={(e) =>
                      handleCheckboxChange(o.value, e.target.checked)
                    }
                  />
                }
                label={o.label}
              />
            </Stack>
          ))}
        </Box>

        <Stack direction="row" spacing={2}>
          <Button type="submit">{initialData ? "Update" : "Add"}</Button>
          <Show when={Boolean(initialData)}>
            <Button variant="outlined" onClick={onDeleteClick}>
              Delete
            </Button>
          </Show>
          <Button variant="outlined" onClick={onCancelClick}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
