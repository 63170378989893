import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_SETTLEMENT_ORDERS } from "entities/settlement-orders";
import { getSettlemenOrders } from "shared/api";

type UseSettlementOrdersParams = {
  settlementId?: string;
};

export const useSettlementOrders = ({
  settlementId,
}: UseSettlementOrdersParams) =>
  useQuery({
    queryFn: () => getSettlemenOrders({ settlementId }),
    queryKey: [QUERY_KEY_SETTLEMENT_ORDERS, settlementId],
    enabled: Boolean(settlementId),
  });
