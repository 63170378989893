import { TradeOutletsOption } from "entities/trade-outlets";
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "shared/ui";

type FilterTradeOutletProps = {
  onChange: (e: SelectChangeEvent<unknown>) => void;
  options: TradeOutletsOption[];
  value?: string;
};

export const FilterTradeOutlet = ({
  options,
  value,
  onChange,
}: FilterTradeOutletProps) => {
  return (
    <Box flex={1}>
      <Select
        displayEmpty
        value={value}
        onChange={onChange}
        renderValue={(id: unknown) => {
          if (id)
            return <div>{options.find((o) => o.value === id)?.label}</div>;

          return <Typography color="common.surface4">Store</Typography>;
        }}
        size="small"
        variant="filled"
      >
        <MenuItem>
          <em>Show All</em>
        </MenuItem>
        {options.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
