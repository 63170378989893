import { useSettlementOrders } from "entities/settlement-orders";
import { SettlementDetailedView, useSettlements } from "entities/settlements";
import { useState } from "react";
import { GetSettlementsResponseData } from "shared/api";
import { useIsDesktop } from "shared/lib";
import { Drawer, Grid, Stack, Typography } from "shared/ui";

import { COLUMNS } from "../lib";

export const SettlementList = () => {
  const isDesktop = useIsDesktop();

  const [selectedItem, setSelectedItem] =
    useState<GetSettlementsResponseData>();

  const { data: settlements } = useSettlements();
  const { data: orders } = useSettlementOrders({
    settlementId: selectedItem?.id,
  });

  const handleRowSelect = (row: GetSettlementsResponseData) => {
    setSelectedItem(row);
  };

  const handleDetailedViewClose = () => {
    setSelectedItem(undefined);
  };

  return (
    <>
      <Stack direction="row" spacing={5} alignItems="center">
        <Typography variant="title5">Settlements</Typography>
      </Stack>

      <Grid
        columns={COLUMNS}
        data={settlements}
        noDataMessage="You don't have any settlements yet"
        selectedRow={selectedItem}
        onRowSelect={handleRowSelect}
      />

      <Drawer
        anchor="right"
        open={Boolean(selectedItem)}
        onClose={handleDetailedViewClose}
        sx={{
          ".MuiDrawer-paper": {
            width: isDesktop ? 800 : "100%",
          },
        }}
      >
        <Stack p={2}>
          <SettlementDetailedView
            settlement={selectedItem}
            orders={orders}
            onClose={handleDetailedViewClose}
          />
        </Stack>
      </Drawer>
    </>
  );
};
