import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetEmployeesResponseData = {
  id: bnplMerchants.EmployeeMany["id"];
  archived: bnplMerchants.EmployeeMany["archived"];
  created: bnplMerchants.EmployeeMany["created"];
  merchantId: bnplMerchants.EmployeeMany["merchant_id"];
  name: bnplMerchants.EmployeeMany["name"];
  role: bnplMerchants.EmployeeMany["role"];
  phone: bnplMerchants.EmployeeMany["phone"];
  email: bnplMerchants.EmployeeMany["email"];
};

export const getEmployees = async (): Promise<GetEmployeesResponseData[]> => {
  const response = await bnplMerchants.getApiMerchantsServiceEmployees();

  return (
    response.data.data?.items?.map((i) => ({
      id: i.id,
      archived: i.archived,
      created: i.created,
      merchantId: i.merchant_id,
      name: i.name,
      role: i.role,
      phone: i.phone,
      email: i.email,
    })) || []
  );
};
