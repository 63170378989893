import { DatePicker } from "shared/ui";

type FilterDateProps = {
  onChange: (date: Date | Date[] | undefined) => void;
  value?: Date[];
};

export const FilterDate = ({ value, onChange }: FilterDateProps) => {
  const handleChange = (val: Date | Date[] | undefined) => {
    onChange(val);
  };

  return (
    <DatePicker
      placeholder="Date"
      numberOfMonths={2}
      range
      value={value}
      onChange={handleChange}
    />
  );
};
