import { FilterOrdersState } from "features/filter-orders";
import { GetOrdersItem } from "shared/api";

export const getFilteredOrders = (
  filters: FilterOrdersState,
  orders?: GetOrdersItem[],
): GetOrdersItem[] => {
  if (!orders) return [];

  const filteredOrders = orders.filter((order) => {
    const validation = {
      tradeOutletId: true,
      status: true,
      date: true,
      employeeId: true,
    };

    if (filters.tradeOutletId)
      validation.tradeOutletId = order.tradeOutletId === filters.tradeOutletId;

    if (filters.status) validation.status = order.status === filters.status;

    if (filters.date && order.created && filters.date.length === 2) {
      validation.date =
        order.created.valueOf() >= filters.date[0].valueOf() &&
        order.created.valueOf() <= filters.date[1].valueOf();
    }

    if (filters.employeeId)
      validation.employeeId = order.employeeId === filters.employeeId;

    return !Object.values(validation).some((value) => !value);
  });

  return filteredOrders;
};
