import { useMutation } from "@tanstack/react-query";
import { queryClient } from "app/providers";
import { QUERY_KEY_EMPLOYEES } from "entities/employees";
import { deleteEmployee } from "shared/api";

export const useDeleteEmployee = () =>
  useMutation({
    mutationFn: deleteEmployee,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY_EMPLOYEES });
    },
  });
