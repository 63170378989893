import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type UpdateOrderRequestParams = {
  orderId: string;
  payload: bnplMerchants.UpdateOrderOnlyAmount;
};

export type UpdateOrderResponseData = {
  amount: bnplMerchants.Order["amount"];
  applicationId: bnplMerchants.Order["application_id"];
  archived: bnplMerchants.Order["archived"];
  calcId: bnplMerchants.Order["calc_id"];
  cardId: bnplMerchants.Order["card_id"];
  clientId: bnplMerchants.Order["client_id"];
  contractId: bnplMerchants.Order["contract_id"];
  created: bnplMerchants.Order["created"];
  downPayment: bnplMerchants.Order["down_payment"];
  id: bnplMerchants.Order["id"];
  link: bnplMerchants.Order["link"];
  offerId: bnplMerchants.Order["offer_id"];
  orderNumber: bnplMerchants.Order["order_number"];
  status: bnplMerchants.Order["status"];
  tradeOutletId: bnplMerchants.Order["trade_outlet_id"];
  updated: bnplMerchants.Order["updated"];
};

export const updateOrder = async ({
  orderId,
  payload,
}: UpdateOrderRequestParams): Promise<UpdateOrderResponseData> => {
  const response = await bnplMerchants.patchApiMerchantsServiceOrdersId(
    orderId,
    payload,
  );

  if (!response.data.data) {
    throw new Error("Error updating order");
  }

  return {
    amount: response.data.data?.amount,
    applicationId: response.data.data.application_id,
    archived: response.data.data.archived,
    calcId: response.data.data.calc_id,
    cardId: response.data.data.card_id,
    clientId: response.data.data.client_id,
    contractId: response.data.data.contract_id,
    created: response.data.data.created,
    downPayment: response.data.data.down_payment,
    id: response.data.data.id,
    link: response.data.data.link,
    offerId: response.data.data.offer_id,
    orderNumber: response.data.data.order_number,
    status: response.data.data.status,
    tradeOutletId: response.data.data.trade_outlet_id,
    updated: response.data.data.updated,
  };
};
