import { useState } from "react";
import { COUNTRY_CODE } from "shared/config";
import { Box, Button, InputPhone } from "shared/ui";

import { getCodeErrorMessage, useGetCode } from "../../model";

export type EnterPhoneNumberViewProps = {
  error: unknown;
  onSubmit: (values: { phoneNumber: string }) => void;
};

export const EnterPhoneNumberView = ({
  error,
  onSubmit,
}: EnterPhoneNumberViewProps) => {
  const getCode = useGetCode();

  const [phoneNumber, setPhoneNumber] = useState("");

  const handleInputPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.currentTarget.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit({ phoneNumber });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <InputPhone
        autoFocus={true}
        countryCode={COUNTRY_CODE}
        error={Boolean(error)}
        helperText={getCodeErrorMessage(error)}
        inputProps={{ type: "tel" }}
        label="Enter phone number"
        onChange={handleInputPhoneChange}
        sx={{ mb: 2 }}
      />
      <Button fullWidth={true} loading={getCode.isPending} type="submit">
        Request Code via SMS
      </Button>
    </Box>
  );
};
