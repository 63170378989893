import { Button } from "..";
import { Dialog } from "../dialog";
import { DialogActions } from "../dialog-actions";
import { DialogTitle } from "../dialog-title";

type ConfirmDialogProps = {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmDialog = ({
  onConfirm,
  onClose,
  isOpen,
  title,
}: ConfirmDialogProps) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogActions>
        <Button
          color="warning"
          onClick={onClose}
          size="small"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          autoFocus
          onClick={handleConfirm}
          size="small"
          sx={{ color: "common.white" }}
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
