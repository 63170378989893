import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetTradeOutletsResponseData = {
  id: bnplMerchants.TradeOutletMany["id"];
  agreementId: bnplMerchants.TradeOutletMany["agreement_id"];
  locationGeo: bnplMerchants.TradeOutletMany["location_geo"];
  name: bnplMerchants.TradeOutletMany["name"];
};

export const getTradeOutlets = async (): Promise<
  GetTradeOutletsResponseData[]
> => {
  const response = await bnplMerchants.getApiMerchantsServiceTradeOutlets();

  return (
    response.data.data?.items?.map((i) => ({
      id: i.id,
      agreementId: i.agreement_id,
      locationGeo: i.location_geo,
      name: i.name,
    })) || []
  );
};
