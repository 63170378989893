import { useEmployees } from "..";

export type EmployeesOption = {
  value: string | undefined;
  label: string;
};

export const useEmployeesOptions = (): {
  employeesOptions: EmployeesOption[];
} => {
  const { data } = useEmployees();

  return {
    employeesOptions: data
      ? data.map((employee) => ({
          value: employee.id,
          label: employee.name,
        }))
      : [],
  };
};
