import { useProfile, useUpdateProfile } from "features/update-profile";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { showSnackbarError, showSnackbarSuccess } from "shared/lib";
import { Box, Button, Input, Stack, Typography } from "shared/ui";

type ProfileFormData = {
  name: string;
  phone: string;
  email: string;
};

export const ProfileForm = () => {
  const { data } = useProfile();

  const { handleSubmit, register, reset } = useForm<ProfileFormData>();

  const updateProfile = useUpdateProfile();

  useEffect(() => {
    if (data) {
      reset({
        name: data.name,
        phone: data.phone,
        email: data.email,
      });
    }
  }, [data, reset]);

  const handleFormSubmit = ({ name, phone, email }: ProfileFormData) => {
    updateProfile.mutate(
      {
        employeeId: data?.id!,
        payload: {
          name,
          phone,
          email,
        },
      },
      {
        onSuccess: () => {
          showSnackbarSuccess("Data was successfully updated");
        },
        onError: (error: Error) => {
          // @TODO Remove ts-ignore, unify show error functions
          // @ts-ignore
          const emailError = error?.response?.data?.error?.errors?.email?.[0];
          // @ts-ignore
          const phoneError = error?.response?.data?.error?.errors?.phone?.[0];

          if (emailError) {
            showSnackbarError(emailError);
          }

          if (phoneError) {
            showSnackbarError(phoneError);
          }
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing={2}>
        <Box>
          <Typography flex={1}>Name</Typography>
          <Box flex={1}>
            <Input {...register("name")} required />
          </Box>
        </Box>

        <Box>
          <Typography flex={1}>Phone</Typography>
          <Box flex={1}>
            <Input {...register("phone")} required />
          </Box>
        </Box>

        <Box>
          <Typography flex={1}>Email</Typography>
          <Box flex={1}>
            <Input {...register("email")} required />
          </Box>
        </Box>

        <Button type="submit">Save</Button>
      </Stack>
    </form>
  );
};
