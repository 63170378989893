import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { enqueueSnackbar } from "@mychili/ui-web";

export type GetEmployeeOutletsResponseData = {
  id: bnplMerchants.EmployeeOutletMany["id"];
  archived: bnplMerchants.EmployeeOutletMany["archived"];
  employeeId: bnplMerchants.EmployeeOutletMany["employee_id"];
  tradeOutletId: bnplMerchants.EmployeeOutletMany["trade_outlet_id"];
};

export const getEmployeeOutlets = async (): Promise<
  GetEmployeeOutletsResponseData[]
> => {
  const response = await bnplMerchants.getApiMerchantsServiceEmployeeOutlets();

  if (!response.data.data) {
    enqueueSnackbar("Error getting employee outlets", { variant: "error" });
    return [];
  }

  return (
    response.data.data.items?.map((i) => ({
      id: i.id,
      archived: i.archived,
      employeeId: i.employee_id,
      tradeOutletId: i.trade_outlet_id,
    })) || []
  );
};
