import { ROUTES } from "shared/routing";

export const NAVIGATION_ITEMS = [
  {
    title: "Orders",
    route: ROUTES.orders,
  },
  {
    title: "Employees",
    route: ROUTES.employees,
  },
  {
    title: "Stores",
    route: ROUTES.tradeOutlets,
  },
  {
    title: "Payout",
    route: ROUTES.settlements,
  },
];
