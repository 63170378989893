import { TradeOutletsIdsState } from "features/update-employee";

type EmployeeOutletsPayload = {
  bindIds: string[];
  detachIds: string[];
};

export const getEmployeeOutletsPayload = (
  tradeOutletsIds: TradeOutletsIdsState,
): EmployeeOutletsPayload => {
  const bindIds: string[] = [];
  const detachIds: string[] = [];

  Object.values(tradeOutletsIds).forEach((item) => {
    if (item.checked) {
      bindIds.push(item.value);
    } else {
      detachIds.push(item.value);
    }
  });

  return {
    bindIds,
    detachIds,
  };
};
