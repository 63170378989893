import { useBoolean } from "@mychili/ui-web";
import { useAgreements } from "entities/agreements";
import { useTradeOutlets } from "entities/trade-outlets";
import { useCreateTradeOutlet } from "features/create-trade-outlet";
import { useDeleteTradeOutlet } from "features/delete-trade-outlet";
import {
  TradeOutletForm,
  TradeOutletFormData,
  useUpdateTradeOutlet,
} from "features/update-trade-outlet";
import { useState } from "react";
import { GetTradeOutletsResponseData } from "shared/api";
import {
  showSnackbarError,
  showSnackbarSuccess,
  useIsDesktop,
} from "shared/lib";
import {
  Button,
  ConfirmDialog,
  Drawer,
  Grid,
  Stack,
  Typography,
} from "shared/ui";

import { COLUMNS } from "../lib";

export const TradeOutletList = () => {
  const isDesktop = useIsDesktop();

  const createTradeOutlet = useCreateTradeOutlet();
  const updateTradeOutlet = useUpdateTradeOutlet();
  const deleteTradeOutlet = useDeleteTradeOutlet();

  const tradeOutlets = useTradeOutlets();
  const agreements = useAgreements();

  const [selectedItem, setSelectedItem] =
    useState<GetTradeOutletsResponseData>();

  const isAddingNewRecord = useBoolean(false);
  const confirmDialog = useBoolean();

  const handleDelete = () => {
    deleteTradeOutlet.mutate(
      {
        tradeOutletId: selectedItem?.id!,
      },
      {
        onError: () => {
          showSnackbarError("Error deleting trade outlet");
        },
        onSuccess: () => {
          handleUpdateFormClose();
          showSnackbarSuccess("Trade outlet was successfully deleted");
        },
      },
    );
  };

  const handleFormSubmit = (formData: TradeOutletFormData) => {
    if (isAddingNewRecord.value) {
      createTradeOutlet.mutate(
        {
          payload: {
            name: formData.name,
            location_geo: formData.locationGeo,
            agreement_id: formData.agreementId,
          },
        },
        {
          onError: () => {
            showSnackbarError("Error creating trade outlet");
          },
          onSuccess: () => {
            showSnackbarSuccess("Trade outlet was successfully created");
            isAddingNewRecord.off();
          },
        },
      );
    } else {
      updateTradeOutlet.mutate(
        {
          tradeOutletId: selectedItem!.id!,
          payload: {
            name: formData.name,
            location_geo: formData.locationGeo!,
            agreement_id: formData.agreementId,
          },
        },
        {
          onError: () => {
            showSnackbarError("Error updating trade outlet");
          },
          onSuccess: () => {
            showSnackbarSuccess("Trade outlet was successfully updated");
          },
        },
      );
    }
  };

  const handleTradeOutletFormClose = () => {
    setSelectedItem(undefined);
    isAddingNewRecord.off();
  };

  const handleUpdateFormClose = () => setSelectedItem(undefined);

  const handleRowSelect = (row: GetTradeOutletsResponseData) => {
    setSelectedItem(row);
  };

  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="title5">Trade outlets</Typography>
        <Button variant="outlined" onClick={isAddingNewRecord.on}>
          +Add
        </Button>
      </Stack>

      <Grid
        columns={COLUMNS}
        data={tradeOutlets.data}
        noDataMessage="You don't have any trade outlets yet"
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
      />

      <Drawer
        anchor="right"
        open={isAddingNewRecord.value || Boolean(selectedItem)}
        onClose={
          isAddingNewRecord.value
            ? isAddingNewRecord.off
            : handleUpdateFormClose
        }
        sx={{
          ".MuiDrawer-paper": {
            width: isDesktop ? 600 : "100%",
          },
        }}
      >
        <Stack p={2}>
          <TradeOutletForm
            initialData={selectedItem}
            onCancelClick={handleTradeOutletFormClose}
            onDeleteClick={confirmDialog.on}
            onSubmit={handleFormSubmit}
            agreements={agreements.data || []}
          />
        </Stack>
      </Drawer>

      <ConfirmDialog
        onClose={confirmDialog.off}
        onConfirm={handleDelete}
        isOpen={confirmDialog.value}
        title={`Delete outlet ${selectedItem?.name}?`}
      />
    </>
  );
};
