import { TradeOutletsOption } from "entities/trade-outlets";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { GetOrdersItem } from "shared/api";
import {
  Box,
  Button,
  Input,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "shared/ui";

export type OrderFormData = {
  amount: number;
  tradeOutletId: string;
};

type OrderFormProps = {
  onCancelClick: () => void;
  onSubmit: (formData: OrderFormData) => void;
  tradeOutletsOptions: TradeOutletsOption[];
  initialData?: GetOrdersItem;
};

export const OrderForm = ({
  initialData,
  onCancelClick,
  onSubmit,
  tradeOutletsOptions,
}: OrderFormProps) => {
  const { handleSubmit, control, register, reset } = useForm<OrderFormData>();

  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
  }, [initialData, reset]);

  return (
    <Stack spacing={2}>
      <Typography variant="bigTextSemiBold">
        {initialData ? "Updating order" : "Adding new order"}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Box>
            <Typography flex={1}>Amount</Typography>
            <Box flex={1}>
              <Input {...register("amount")} required />
            </Box>
          </Box>

          <Box>
            <Typography flex={1}>Store</Typography>
            <Box flex={1}>
              <Controller
                control={control}
                name="tradeOutletId"
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={Boolean(initialData)}
                    variant="filled"
                    required
                  >
                    {tradeOutletsOptions.map((o) => (
                      <MenuItem key={o.value} value={o.value}>
                        {o.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </Box>

          <Stack direction="row" spacing={2} alignContent="center">
            <Button type="submit">{initialData ? "Update" : "Add"}</Button>
            <Button onClick={onCancelClick} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};
