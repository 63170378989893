import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_ORDERS } from "entities/orders";
import { updateOrder } from "shared/api";

export const useUpdateOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateOrder,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY_ORDERS });
    },
  });
};
